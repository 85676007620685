import React from 'react';
import {SVGModel} from '../../../framework.visual/model/svgModel';

export const GraphSVG = ({className} : SVGModel) => (
    <svg className={className} viewBox="0 0 32 32">
        <path d="M 0.50196642,27.916484 C -0.00732539,27.732403 5.7409988e-6,27.907173 5.7409988e-6,15.951217 5.7409988e-6,4.9679652 0.01034613,4.4401949 0.22949463,4.2418703 0.41721412,4.071988 0.74383994,4.0341865 2.0240042,4.0341865 c 1.049918,0 1.6145843,0.049564 1.7156087,0.1505883 0.1145598,0.1145599 0.1505882,2.5014372 0.1505882,9.9764682 v 9.82588 H 17.69412 c 13.6366,0 13.806961,0.003 14.0549,0.250981 0.222642,0.222642 0.25098,0.4183 0.25098,1.732877 0,1.081708 -0.0481,1.547681 -0.178107,1.72549 -0.174485,0.23864 -0.492779,0.244045 -15.654899,0.265821 -8.5122358,0.01222 -15.56149819,-0.0084 -15.66502758,-0.04581 z M 6.023534,18.726853 V 15.474425 L 8.7484389,10.931753 C 10.247135,8.433282 11.589578,6.312919 11.731644,6.219834 c 0.142065,-0.09309 0.382032,-0.136889 0.53326,-0.09734 0.174627,0.04567 1.266493,1.394952 2.99223,3.697681 1.494496,1.994177 2.739261,3.625776 2.766142,3.625776 0.02688,0 1.299746,-0.819814 2.828589,-1.821808 2.734271,-1.792024 3.018602,-1.924693 3.393244,-1.583293 0.08238,0.07507 4.59099,9.423905 5.379237,11.154119 l 0.357314,0.784314 H 18.002598 6.023534 Z"/>
    </svg>
);



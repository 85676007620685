/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { LockManager } from './LockManager';
import type { Pocket_Excerpt } from './Pocket_Excerpt';
import type { Pocket_Note } from './Pocket_Note';
import type { Pocket_Resource } from './Pocket_Resource';
import type { Pocket_Source } from './Pocket_Source';
import type { SharingGroup } from './SharingGroup';

export type GetPocket = {
    /**
     * Current user's access role to the Pocket; O - Owner, G - Group shared, U - Unknown
     */
    access_role?: GetPocket.access_role;
    author_id?: string;
    created_at?: string;
    created_by?: string;
    excerpts?: Array<Pocket_Excerpt>;
    group?: SharingGroup;
    kp_type?: string;
    lock_manager?: LockManager;
    note_ids?: Array<string>;
    notes?: Array<Pocket_Note>;
    report_ids?: Array<string>;
    resources?: Array<Pocket_Resource>;
    scope?: GetPocket.scope;
    sources?: Array<Pocket_Source>;
    title?: string;
    updated_at?: string;
    updated_by?: string;
};

export namespace GetPocket {

    /**
     * Current user's access role to the Pocket; O - Owner, G - Group shared, U - Unknown
     */
    export enum access_role {
        O = 'O',
        G = 'G',
        U = 'U',
    }

    export enum scope {
        DRAFT = 'Draft',
        PRIVATE = 'Private',
        GROUP = 'Group',
    }


}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { AuthorizeService } from './services/AuthorizeService';
import { KnowledgeProductKpService } from './services/KnowledgeProductKpService';
import { LaunchPadService } from './services/LaunchPadService';
import { MetaService } from './services/MetaService';
import { PocketReportsService } from './services/PocketReportsService';
import { PocketsService } from './services/PocketsService';
import { ReferenceDataService } from './services/ReferenceDataService';
import { StatsService } from './services/StatsService';
import { TeamAndDepartmentService } from './services/TeamAndDepartmentService';
import { UserGuidesService } from './services/UserGuidesService';
import { UsersService } from './services/UsersService';
import { UsersRolesService } from './services/UsersRolesService';
import { UsersRolesFunctionsService } from './services/UsersRolesFunctionsService';
import { UsersRolesRequestsService } from './services/UsersRolesRequestsService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class KMApi {

    public readonly authorize: AuthorizeService;
    public readonly knowledgeProductKp: KnowledgeProductKpService;
    public readonly launchPad: LaunchPadService;
    public readonly meta: MetaService;
    public readonly pocketReports: PocketReportsService;
    public readonly pockets: PocketsService;
    public readonly referenceData: ReferenceDataService;
    public readonly stats: StatsService;
    public readonly teamAndDepartment: TeamAndDepartmentService;
    public readonly userGuides: UserGuidesService;
    public readonly users: UsersService;
    public readonly usersRoles: UsersRolesService;
    public readonly usersRolesFunctions: UsersRolesFunctionsService;
    public readonly usersRolesRequests: UsersRolesRequestsService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '/KMAPI',
            VERSION: config?.VERSION ?? '1.3.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.authorize = new AuthorizeService(this.request);
        this.knowledgeProductKp = new KnowledgeProductKpService(this.request);
        this.launchPad = new LaunchPadService(this.request);
        this.meta = new MetaService(this.request);
        this.pocketReports = new PocketReportsService(this.request);
        this.pockets = new PocketsService(this.request);
        this.referenceData = new ReferenceDataService(this.request);
        this.stats = new StatsService(this.request);
        this.teamAndDepartment = new TeamAndDepartmentService(this.request);
        this.userGuides = new UserGuidesService(this.request);
        this.users = new UsersService(this.request);
        this.usersRoles = new UsersRolesService(this.request);
        this.usersRolesFunctions = new UsersRolesFunctionsService(this.request);
        this.usersRolesRequests = new UsersRolesRequestsService(this.request);
    }
}

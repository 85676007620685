/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { basic_response } from '../models/basic_response';
import type { GetUser } from '../models/GetUser';
import type { PostUser } from '../models/PostUser';
import type { PutUser } from '../models/PutUser';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UsersService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Creates a new User
     * @param requestBody 
     * @returns basic_response Created
     * @throws ApiError
     */
    public create(
requestBody: PostUser,
): CancelablePromise<basic_response> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                407: `An active user with same username (DOD ID) already exists`,
                409: `A new user with same username (DOD ID) already exists`,
                500: `Internal system error`,
            },
        });
    }

    /**
     * Searches User Object
     * @param searchKeyword 
     * @param status 
     * @returns GetUser Success
     * @throws ApiError
     */
    public search(
searchKeyword?: string,
status?: 'Created' | 'Active' | 'Inactive',
): CancelablePromise<GetUser> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/search',
            query: {
                'search_keyword': searchKeyword,
                'status': status,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal system error`,
            },
        });
    }

    /**
     * Deletes a User
     * @param userId 
     * @returns basic_response Success
     * @throws ApiError
     */
    public delete(
userId: string = 'e3a5c7da-9617-44a1-9fb3-7c63c517bf06',
): CancelablePromise<basic_response> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                400: `Bad Request`,
                404: `User does not exists`,
                500: `Internal system error`,
            },
        });
    }

    /**
     * Retrieves a User profile
     * @param userId 
     * @returns GetUser Success
     * @throws ApiError
     */
    public get(
userId: string = 'e3a5c7da-9617-44a1-9fb3-7c63c517bf06',
): CancelablePromise<GetUser> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                400: `Bad Request`,
                401: `Access token is missing or invalid`,
                404: `User does not exists`,
                500: `Internal system error`,
            },
        });
    }

    /**
     * Updates a User Profile
     * @param requestBody 
     * @param userId 
     * @returns GetUser Success
     * @throws ApiError
     */
    public update(
requestBody: PutUser,
userId: string = 'e3a5c7da-9617-44a1-9fb3-7c63c517bf06',
): CancelablePromise<GetUser> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/users/{user_id}',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `User does not exists`,
                500: `Internal system error`,
            },
        });
    }

}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetRoleFunction } from '../models/GetRoleFunction';
import type { PutRoleFunction } from '../models/PutRoleFunction';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UsersRolesFunctionsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retreives all the Functions a user has been granted
     * @param userId 
     * @returns any Success
     * @throws ApiError
     */
    public roleFunction(
userId: string = 'e3a5c7da-9617-44a1-9fb3-7c63c517bf06',
): CancelablePromise<{
function_name?: Array<string>;
role_name?: Array<string>;
}> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/-role-function/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                400: `Bad Request`,
                404: `User does not exists or role(s) not defined yet`,
                500: `Internal system error`,
            },
        });
    }

    /**
     * Searches all the Functions attaches to a Role
     * @param searchKeyword 
     * @returns GetRoleFunction Success
     * @throws ApiError
     */
    public search(
searchKeyword?: string,
): CancelablePromise<GetRoleFunction> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/roles/functions/search',
            query: {
                'search_keyword': searchKeyword,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal system error`,
            },
        });
    }

    /**
     * Retrieves all the Functions attached to Role
     * @param roleId 
     * @returns GetRoleFunction Success
     * @throws ApiError
     */
    public get(
roleId: number,
): CancelablePromise<GetRoleFunction> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/roles/functions/{role_id}',
            path: {
                'role_id': roleId,
            },
            errors: {
                400: `Bad Request`,
                401: `Access token is missing or invalid`,
                404: `Role does not exists`,
                500: `Internal system error`,
            },
        });
    }

    /**
     * Updates Functions attached to a Role
     * @param roleId 
     * @param requestBody 
     * @returns GetRoleFunction Success
     * @throws ApiError
     */
    public update(
roleId: number,
requestBody: PutRoleFunction,
): CancelablePromise<GetRoleFunction> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/users/roles/functions/{role_id}',
            path: {
                'role_id': roleId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Role Function does not exists`,
                500: `Internal system error`,
            },
        });
    }

    /**
     * Retreives all the Functions a user has been granted
     * @param userId 
     * @returns any Success
     * @throws ApiError
     */
    public getRoleNFunctions(
userId: string = 'e3a5c7da-9617-44a1-9fb3-7c63c517bf06',
): CancelablePromise<{
function_name?: Array<string>;
role_name?: Array<string>;
}> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/roles/functions/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                400: `Bad Request`,
                404: `User does not exists or role(s) not defined yet`,
                500: `Internal system error`,
            },
        });
    }

}

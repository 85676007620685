/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetTeamMember = {
    first_name?: string;
    is_active?: GetTeamMember.is_active;
    last_name?: string;
    member_id?: string;
    screen_name?: string;
};

export namespace GetTeamMember {

    export enum is_active {
        Y = 'Y',
        N = 'N',
    }


}

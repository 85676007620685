/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { basic_response } from '../models/basic_response';
import type { GetPocketReport } from '../models/GetPocketReport';
import type { PostNPutPocketReport } from '../models/PostNPutPocketReport';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PocketReportsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Creates a Pocket Report
     * @param requestBody 
     * @returns basic_response Report created successfully
     * @throws ApiError
     */
    public create(
requestBody: PostNPutPocketReport,
): CancelablePromise<basic_response> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/pockets/reports',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Internal system error`,
            },
        });
    }

    /**
     * Deletes a Pocket Report
     * @param reportId 
     * @returns basic_response Report deleted successfully
     * @throws ApiError
     */
    public delete(
reportId: string,
): CancelablePromise<basic_response> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/pockets/reports/{report_id}',
            path: {
                'report_id': reportId,
            },
            errors: {
                400: `Bad Request`,
                404: `Report does not exists`,
                500: `Internal system error`,
            },
        });
    }

    /**
     * Retrieves a Pocket Report
     * @param reportId 
     * @returns GetPocketReport Success
     * @throws ApiError
     */
    public get(
reportId: string,
): CancelablePromise<GetPocketReport> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/pockets/reports/{report_id}',
            path: {
                'report_id': reportId,
            },
            errors: {
                400: `Bad Request`,
                404: `Report does not exists`,
                500: `Internal system error`,
            },
        });
    }

    /**
     * Updates a Pocket Report
     * @param reportId 
     * @param requestBody 
     * @returns GetPocketReport Success
     * @throws ApiError
     */
    public update(
reportId: string,
requestBody: PostNPutPocketReport,
): CancelablePromise<GetPocketReport> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/pockets/reports/{report_id}',
            path: {
                'report_id': reportId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Report does not exists`,
                500: `Internal system error`,
            },
        });
    }

    /**
     * Publishes a Pocket Report
     * @param reportId 
     * @returns basic_response Report created successfully
     * @throws ApiError
     */
    public publish(
reportId: string,
): CancelablePromise<basic_response> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/pockets/reports/{report_id}/publish',
            path: {
                'report_id': reportId,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal system error`,
            },
        });
    }

}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ReferenceDataService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieves lookup data - Department, Purpose, Roles, and Tags
     * @param type Lookup data to retrieve
     * @returns any Success
     * @throws ApiError
     */
    public referenceData(
type: 'DEPARTMENT' | 'PURPOSE' | 'ROLE' | 'SHARED-TAGS' = 'DEPARTMENT',
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/references',
            query: {
                'type': type,
            },
            errors: {
                400: `Bad Request`,
                401: `Access token is missing or invalid`,
                500: `Internal system error`,
            },
        });
    }

}

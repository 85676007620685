/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PostTeamMember = {
    is_active?: PostTeamMember.is_active;
    member_id?: string;
    screen_name?: string;
};

export namespace PostTeamMember {

    export enum is_active {
        Y = 'Y',
        N = 'N',
    }


}

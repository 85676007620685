/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Pocket_Excerpt } from './Pocket_Excerpt';
import type { Pocket_Note } from './Pocket_Note';
import type { Pocket_Resource } from './Pocket_Resource';
import type { Pocket_Source } from './Pocket_Source';
import type { SharingGroup } from './SharingGroup';

export type PostNPutPocket = {
    author_id?: string;
    excerpts?: Array<Pocket_Excerpt>;
    group?: SharingGroup;
    note_ids?: Array<string>;
    notes?: Array<Pocket_Note>;
    report_ids?: Array<string>;
    resources?: Array<Pocket_Resource>;
    scope?: PostNPutPocket.scope;
    sources?: Array<Pocket_Source>;
    title: string;
};

export namespace PostNPutPocket {

    export enum scope {
        DRAFT = 'Draft',
        PRIVATE = 'Private',
        GROUP = 'Group',
    }


}

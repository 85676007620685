import React from 'react';
import {SVGModel} from '../../../framework.visual/model/svgModel';

export const ApproveSVG = ({className} : SVGModel) => (
    <svg className={className} viewBox="0 0 32 32">
        <path d="m 15.147124,31.9757 c -1.5879,-0.0721 -3.27542,-0.42219 -4.74694,-0.98472 -3.7785296,-1.44445 -6.7662396,-4.09444 -8.6164196,-7.64247 -0.91024997,-1.74557 -1.47131997,-3.6132 -1.71430997,-5.7064 -0.0798,-0.68759 -0.0786,-2.61812 0.002,-3.31069 0.42854,-3.67893 1.90914997,-6.85707 4.40314997,-9.45136 2.35593,-2.45067 5.47436,-4.09763003 8.8335296,-4.66534003 1.13793,-0.19232 1.81175,-0.23696 3.10479,-0.20572 1.23526,0.0299 2.03357,0.12087 3.0916,0.35247 C 23.618404,1.262 27.328374,3.8907 29.579364,7.5 c 1.26703,2.03159 2.01544,4.17918 2.34674,6.73405 0.0948,0.731 0.0809,2.90774 -0.0236,3.70018 -0.3948,2.99292 -1.46298,5.55166 -3.32311,7.96026 -0.53444,0.69201 -1.859,2.02867 -2.57516,2.59867 -2.60281,2.0716 -5.91821,3.34587 -9.01411,3.46457 -0.37099,0.0142 -0.76216,0.0321 -0.86927,0.0396 -0.10711,0.008 -0.54529,-0.002 -0.97373,-0.0216 z m -2.08054,-8.87494 c 0.17932,-0.0909 1.0141,-0.84854 2.94287,-2.67103 1.4809,-1.3993 4.02882,-3.80354 5.66205,-5.34275 1.63323,-1.53921 3.01924,-2.85743 3.08003,-2.92938 0.14372,-0.17012 0.29075,-0.5056 0.33048,-0.75407 0.0747,-0.46691 -0.18574,-1.05606 -0.58956,-1.3339 -0.25159,-0.1731 -0.74862,-0.31023 -1.0015,-0.27631 -0.49546,0.0665 -0.44921,0.0259 -5.86081,5.14148 l -5.16078,4.87849 -1.82574,-1.84983 C 8.5879744,15.88072 8.6026344,15.89207 7.9658544,15.89052 c -0.29202,-7e-4 -0.41166,0.0229 -0.59744,0.11768 -0.52651,0.26875 -0.81352,0.72495 -0.81352,1.2931 0,0.61905 -0.0303,0.58101 2.56567,3.21691 1.2795096,1.29918 2.4259796,2.42629 2.5476996,2.50468 0.42543,0.27399 0.9533,0.30338 1.39832,0.0779 z"/>
    </svg>
);



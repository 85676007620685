/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { custom_personal_tag } from './custom_personal_tag';
import type { SharingGroup } from './SharingGroup';

export type PutDocument = {
    author?: string;
    custom_personal_tag?: Array<custom_personal_tag>;
    custom_shared_tag?: Array<string>;
    department: number;
    group?: SharingGroup;
    primary_sme_email?: string;
    primary_sme_name?: string;
    primary_sme_phone?: string;
    project?: string;
    publication_date?: string;
    purpose?: string;
    scope: PutDocument.scope;
    secondary_sme_email?: string;
    secondary_sme_name?: string;
    secondary_sme_phone?: string;
    title: string;
};

export namespace PutDocument {

    export enum scope {
        PRIVATE = 'Private',
        GROUP = 'Group',
        PUBLIC = 'Public',
    }


}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetUser = {
    account_status?: GetUser.account_status;
    approved_at?: string;
    approved_by?: string;
    created_at?: string;
    dept_id?: number;
    dod_id?: string;
    email_address?: string;
    first_name?: string;
    last_name?: string;
    middle_initial?: string;
    phone_number?: string;
    preferred_results_view?: GetUser.preferred_results_view;
    roles?: Array<number>;
    updated_at?: string;
    user_id?: string;
};

export namespace GetUser {

    export enum account_status {
        CREATED = 'Created',
        ACTIVE = 'Active',
        INACTIVE = 'Inactive',
    }

    export enum preferred_results_view {
        LIST = 'List',
        VIEW = 'View',
        CARD = 'Card',
    }


}

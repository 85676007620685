/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PutRoleRequest = {
    reviewer_action: PutRoleRequest.reviewer_action;
    reviewer_comment?: string;
    role_approved: number;
    role_approved_by?: string;
    role_approved_effective_from: string;
    role_approved_effective_to?: string;
};

export namespace PutRoleRequest {

    export enum reviewer_action {
        APPROVED = 'Approved',
        REJECTED = 'Rejected',
        ON_HOLD = 'On-Hold',
    }


}

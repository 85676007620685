/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { basic_response } from '../models/basic_response';
import type { GetPocket } from '../models/GetPocket';
import type { LockManager } from '../models/LockManager';
import type { PostNPutPocket } from '../models/PostNPutPocket';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PocketsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Creates a Pocket
     * @param requestBody 
     * @returns basic_response Pocket created successfully
     * @throws ApiError
     */
    public create(
requestBody: PostNPutPocket,
): CancelablePromise<basic_response> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/pockets',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Internal system error`,
            },
        });
    }

    /**
     * Deletes a Pocket
     * @param pocketId 
     * @returns basic_response Pocket deleted successfully
     * @throws ApiError
     */
    public delete(
pocketId: string,
): CancelablePromise<basic_response> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/pockets/{pocket_id}',
            path: {
                'pocket_id': pocketId,
            },
            errors: {
                400: `Bad Request`,
                403: `Failed to remove pocket`,
                404: `Pocket does not exists`,
                409: `Pocket is locked by another user`,
                500: `Internal system error`,
            },
        });
    }

    /**
     * Retrieves a Pocket
     * @param pocketId 
     * @returns GetPocket Success
     * @throws ApiError
     */
    public get(
pocketId: string,
): CancelablePromise<GetPocket> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/pockets/{pocket_id}',
            path: {
                'pocket_id': pocketId,
            },
            errors: {
                400: `Bad Request`,
                404: `Pocket does not exists`,
                500: `Internal system error`,
            },
        });
    }

    /**
     * Lock/Unlock a Pocket or Pocket Report
     * @param pocketId 
     * @param requestBody 
     * @returns LockManager Pocket object locked/unlocked successfully
     * @throws ApiError
     */
    public patch(
pocketId: string,
requestBody: {
request?: 'Lock' | 'Unlock';
/**
 * Specifies a fixed period of time (in seconds) during which an object remains locked. Object will be automatically unlocked after this period.
 */
retention_time?: number;
},
): CancelablePromise<LockManager> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/pockets/{pocket_id}',
            path: {
                'pocket_id': pocketId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                409: `Failed to lock/unlock Pocket objectFailed to lock/unlock Pocket or Pocket Report`,
            },
        });
    }

    /**
     * Updates a Pocket
     * @param pocketId 
     * @param requestBody 
     * @returns GetPocket Success
     * @throws ApiError
     */
    public update(
pocketId: string,
requestBody: PostNPutPocket,
): CancelablePromise<GetPocket> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/pockets/{pocket_id}',
            path: {
                'pocket_id': pocketId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Pocket does not exists`,
                409: `Pocket is locked by another user`,
                500: `Internal system error`,
            },
        });
    }

}

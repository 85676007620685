import React from 'react';
import {SVGModel} from '../../../framework.visual/model/svgModel';

export const DeleteSVG = ({className} : SVGModel) => (
    <svg className={className} viewBox="0 0 32 32">
        <path d="M 2.9137266,28.936119 0,26.019515 4.9974376,21.020407 9.9948758,16.021299 8.7894108,14.702052 C 8.1264063,13.976466 5.88665,11.708183 3.812175,9.6614236 L 0.04040231,5.9400419 2.9339278,3.0436598 c 1.591439,-1.5930101 2.9611008,-2.89638203 3.0436927,-2.89638203 0.082592,0 2.3874608,2.23654543 5.1219295,4.97010203 L 16.071313,10.08748 21.00798,5.1548835 c 2.715166,-2.7129287 4.987782,-4.94963561 5.050257,-4.970461 0.06248,-0.0208254 1.424931,1.2747697 3.027678,2.8790996 L 32,5.9804843 26.991917,10.990242 21.983833,16 26.991917,21.009758 32,26.019515 29.085915,28.936478 c -1.602747,1.60433 -2.964061,2.899977 -3.02514,2.879214 -0.06108,-0.02076 -2.333829,-2.257334 -5.050554,-4.970164 l -4.9395,-4.932416 -4.971467,4.969804 c -2.7343061,2.733394 -5.0390414,4.969806 -5.1216335,4.969806 -0.082592,0 -1.4613443,-1.312472 -3.0638939,-2.916603 z"/>
    </svg>
);



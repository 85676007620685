/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetRoleRequest = {
    requested_at?: string;
    reviewer_action?: GetRoleRequest.reviewer_action;
    reviewer_comment?: string;
    role_approved?: number;
    role_approved_by?: string;
    role_approved_effective_from?: string;
    role_approved_effective_to?: string;
    role_request_id?: number;
    role_requested?: number;
    role_requested_by?: string;
    role_requested_effective_from?: string;
    role_requested_effective_to?: string;
    role_requested_justification?: string;
    updated_at?: string;
};

export namespace GetRoleRequest {

    export enum reviewer_action {
        APPROVED = 'Approved',
        REJECTED = 'Rejected',
        ON_HOLD = 'On-Hold',
    }


}

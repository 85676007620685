/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserGuidesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Returns KM System User Management Guide in PDF format
     * @param guideId 
     * @returns binary A PDF file
     * @throws ApiError
     */
    public userGuide(
guideId: 'KM User Management.pdf' | 'KM Upload Manager.pdf' | 'KM Search.pdf',
): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/guides/{guide_id}',
            path: {
                'guide_id': guideId,
            },
            errors: {
                404: `Document does not exists`,
                500: `Internal system error`,
            },
        });
    }

}

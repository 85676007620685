/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Basic Response Object
 */
export type basic_response = {
    detail?: string;
    status?: basic_response.status;
    title?: basic_response.title;
    type?: string;
};

export namespace basic_response {

    export enum status {
        '_201' = 201,
        '_204' = 204,
        '_404' = 404,
        '_407' = 407,
        '_409' = 409,
    }

    export enum title {
        SUCCESS = 'success',
        FAILED = 'failed',
        NOOP = 'noop',
    }


}

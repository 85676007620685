import React, { Component } from 'react';
import GridTable from '@nadavshaar/react-grid-table';
import './linksPanel.css';
import { LinksState, LinksProps } from "./linksModel";
import { KMApi } from "../../../km.api";
import { authenticationService, userService } from "../../../app.core/serviceComposition";
import Button from "../../theme/widgets/button/button";
import { ArrowRightSVG } from "../../theme/svgs/arrowRightSVG";
import { ArrowLeftSVG } from "../../theme/svgs/arrowLeftSVG";
//import { CellRenderer } from "../../searchResultsPanel/renderers/tableCollectionView.jsx"

export class LinksPanelView extends Component<LinksProps, LinksState> {

    constructor(props: any, context: any) {
        super(props, context);

        this.state = {
            isAlternate: true,
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps: Readonly<LinksProps>, prevState: Readonly<LinksState>, snapshot?: any) {

    }

    //    const response = await fetch(`app/api/rows`, {
    //        method: 'post',
    //        body: {
    //            from: requestData.from,
    //            to: requestData.to,
    //            searchText: requestData.searchText,
    //            sort: requestData.sort,
    //        },
    //    }).then(response => response.json()).catch(console.warn);

    //    if (!response?.items) return;

    //    return {
    //        rows: response.items,
    //        totalRows: response.totalItems
    //    };
    //}

    //Using grid: https://github.com/NadavShaar/react-grid-table
    render() {
        const {
            className, departmentData, purposeData, totalUploadsData, customTagsData, docTypeData, isExpanded,
        } = this.props;

        // custom cell component with an image in it
        const Username = ({ tableManager, value, field, data, column, colIndex, rowIndex }: any) => {
            return (
                <div className='rgt-cell-inner' style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
                    <img src={data.avatar} alt="user avatar" />
                    <span className='rgt-text-truncate' style={{ marginLeft: 10 }}>{value}</span>
                </div>
            )
        }

        const CellRenderer = ({ tableManager, value, field, data, column, colIndex, rowIndex }: any) => {
            return (
                <div className='rgt-cell-inner cursor-pointer d-flex align-items-center'>
                    <div className={"cell-text text-nowrap overflow-hidden header-2"}>{value}</div>
                </div>
            )
        }

        const HeaderCellRenderer = ({ tableManager, column }: any) => {
            return (
                <div className='column-header d-flex align-items-center justify-content-center display-3 text-center'>
                    {column.label}
                </div>
            )
        }

        const OldCellRenderer = ({ tableManager, value, field, data, column, colIndex, rowIndex } : any) => {

            const { selected } = data;

            let cn = 'rgt-cell-inner cursor-pointer d-flex align-items-center';

            if (selected) {
                cn += ` selected`;
            }

            return (
                <div className={cn}>
                    <div className={"cell-text text-nowrap overflow-hidden header-2"}>{value}</div>
                </div>
            )
        }

        //const HeaderCellRenderer = ({ tableManager, column }: any) => {
        //    return (
        //        <div className='column-header d-flex align-items-center justify-content-center display-3 text-center'>
        //            {column.label}
        //        </div>
        //    )
        //}

        const requestDashboardRows = async (requestData: any, tableManager: any) => {
            //This gets called twice right now. Probably a bug in the KM stuff.
            //Get the dashboard list
            //const userId = "e3a5c7da-9617-44a1-9fb3-7c63c517bf06";
            const userId = userService.getCurrentUserId();
            const api = new KMApi({ BASE: process.env.REACT_APP_SERVER_URL, TOKEN: authenticationService.getToken() });
            const rows = await api.launchPad.get(userId) as Array<any>;

            //alert(result);
            //return result;

            //const sampleRows = [
            //    {
            //        "url": "https://www.google.com",
            //        "title": "A sample title",
            //        "access_level": "full",
            //    },
            //    {
            //        "url": "https://www.msn.com",
            //        "title": "Another title",
            //        "access_level": "anonymized",
            //    },
            //]
            return {
                rows: rows,
                totalRows: 2
            };
        }

        //const rows = [
        //    {
        //        "id": 1,
        //        "username": "wotham0",
        //        "gender": "Male",
        //        "last_visited": "12/08/2019",
        //        "test": { "x": 1, "y": 2 },
        //        "avatar": "https://robohash.org/atquenihillaboriosam.bmp?size=32x32&set=set1"
        //    },
        //    {
        //        "id": 2,
        //        "username": "dbraddon2",
        //        "gender": "Female",
        //        "last_visited": "16/07/2018",
        //        "test": { "x": 3, "y": 4 },
        //        "avatar": "https://robohash.org/etsedex.bmp?size=32x32&set=set1"
        //    },
        //    {
        //        "id": 3,
        //        "username": "dridett3",
        //        "gender": "Male",
        //        "last_visited": "20/11/2016",
        //        "test": { "x": 5, "y": 8 },
        //        "avatar": "https://robohash.org/inimpeditquam.bmp?size=32x32&set=set1"
        //    },
        //    {
        //        "id": 4,
        //        "username": "gdefty6",
        //        "gender": "Female",
        //        "last_visited": "03/08/2019",
        //        "test": { "x": 7, "y": 4 },
        //        "avatar": "https://robohash.org/nobisducimussaepe.bmp?size=32x32&set=set1"
        //    },
        //    {
        //        "id": 5,
        //        "username": "hbeyer9",
        //        "gender": "Male",
        //        "last_visited": "10/10/2016",
        //        "test": { "x": 2, "y": 2 },
        //        "avatar": "https://robohash.org/etconsequatureaque.jpg?size=32x32&set=set1"
        //    }
        //];

        const columns = [
            {
                id: 1,
                field: 'url',
                label: 'URL',
                sortable: false,
                resizeable: false,
                //cellRenderer: OldCellRenderer,
                headerCellRenderer: HeaderCellRenderer
            },
            {
                id: 2,
                field: 'title',
                label: 'Title',
                sortable: false,
                resizeable: false,
                //cellRenderer: OldCellRenderer,
                headerCellRenderer: HeaderCellRenderer
            },
            {
                id: 3,
                field: 'access_level',
                label: 'Access Level',
                sortable: false,
                resizeable: false,
                //cellRenderer: OldCellRenderer,
                headerCellRenderer: HeaderCellRenderer
            },
        //    {
        //        id: 4,
        //        field: 'access status',
        //        label: 'Score',
        //        sortable: false,
        //        resizeable: false,
        //        getValue: ({ value, column }: any) => value.x + value.y,
        //        //cellRenderer: CellRenderer
        //    }
        ];

        //Grid table documentation:
        //https://github.com/NadavShaar/react-grid-table
        return (
            <div className={'table h-100 w-100 position-relative'}>
                <GridTable showSearch={false} className={'position-absolute h-100'}
                    columns={columns} enableColumnsReorder={false}
                    onRowsRequest={requestDashboardRows}
                    showColumnVisibilityManager={false} showRowsInformation={false}
                    isPaginated={false} />
            </div>
        );
    }
}

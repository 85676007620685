/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type LockManager = {
    /**
     * Id of user who has locked the object
     */
    locked_by?: string;
    /**
     * Amount of time in seconds left until object is locked. 0 means object is not locked.
     */
    remaining_time?: number;
    /**
     * Object status
     */
    status: LockManager.status;
};

export namespace LockManager {

    /**
     * Object status
     */
    export enum status {
        LOCKED = 'Locked',
        UNLOCKED = 'Unlocked',
    }


}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { basic_response } from '../models/basic_response';
import type { document_upload_response } from '../models/document_upload_response';
import type { GetDocument } from '../models/GetDocument';
import type { PutDocument } from '../models/PutDocument';
import type { Search } from '../models/Search';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class KnowledgeProductKpService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Creates/Uploads a Knowledge Products (KP)
     * @param formData 
     * @returns document_upload_response Document uploaded successfully
     * @throws ApiError
     */
    public create(
formData: {
filename: Array<Blob>;
user_id?: string;
},
): CancelablePromise<document_upload_response> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/documents',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                415: `Unsupported media type`,
                500: `Internal system error`,
            },
        });
    }

    /**
     * Searches Knowledge Products (KPs)
     * @param requestBody 
     * @returns GetDocument Success
     * @throws ApiError
     */
    public search(
requestBody: Search,
): CancelablePromise<GetDocument> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/documents/search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Internal system error`,
            },
        });
    }

    /**
     * Deletes a Knowledge Product
     * @param documentId 
     * @returns basic_response Document deleted successfully
     * @throws ApiError
     */
    public delete(
documentId: string,
): CancelablePromise<basic_response> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/documents/{document_id}',
            path: {
                'document_id': documentId,
            },
            errors: {
                400: `Bad Request`,
                404: `Document does not exists`,
                500: `Internal system error`,
            },
        });
    }

    /**
     * Retrieves a Knowledge Product's metadata or Downloads a KP
     * @param documentId 
     * @param format 
     * @returns GetDocument Success
     * @throws ApiError
     */
    public get(
documentId: string,
format?: 'PREVIEW' | 'ORIGINAL',
): CancelablePromise<GetDocument> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/documents/{document_id}',
            path: {
                'document_id': documentId,
            },
            query: {
                'format': format,
            },
            errors: {
                400: `Bad Request`,
                404: `Document does not exists`,
                500: `Internal system error`,
            },
        });
    }

    /**
     * Updates Knowledge Product's Metadata
     * @param documentId 
     * @param requestBody 
     * @returns PutDocument Success
     * @throws ApiError
     */
    public update(
documentId: string,
requestBody: PutDocument,
): CancelablePromise<PutDocument> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/documents/{document_id}',
            path: {
                'document_id': documentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Document does not exists`,
                500: `Internal system error`,
            },
        });
    }

}

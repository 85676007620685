/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetLaunchPadResponse } from '../models/GetLaunchPadResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LaunchPadService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieves a User's Dashboard related access
     * @param userId 
     * @returns GetLaunchPadResponse Success
     * @throws ApiError
     */
    public get(
userId: string = 'e3a5c7da-9617-44a1-9fb3-7c63c517bf06',
): CancelablePromise<GetLaunchPadResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/launchpad/dashboards/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal system error`,
            },
        });
    }

    /**
     * Retrieves a User's Datasource related access
     * @param userId 
     * @returns GetLaunchPadResponse Success
     * @throws ApiError
     */
    public get1(
userId: string = 'e3a5c7da-9617-44a1-9fb3-7c63c517bf06',
): CancelablePromise<GetLaunchPadResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/launchpad/datasources/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal system error`,
            },
        });
    }

    /**
     * Retrieves a User's Tool related access
     * @param userId 
     * @returns GetLaunchPadResponse Success
     * @throws ApiError
     */
    public get2(
userId: string = 'e3a5c7da-9617-44a1-9fb3-7c63c517bf06',
): CancelablePromise<GetLaunchPadResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/launchpad/tools/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal system error`,
            },
        });
    }

}

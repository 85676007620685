import React from 'react';
import {SVGModel} from '../../../framework.visual/model/svgModel';

export const TextEditSVG = ({className} : SVGModel) => (
    <svg className={className} viewBox="0 0 32 32">
        <path d="m 10.554728,31.974655 c -0.18482,-0.0567 -0.307,-0.16318 -0.3712,-0.32363 -0.085,-0.21232 -0.0903,-1.2897 -0.007,-1.48034 0.0312,-0.0716 0.7418,-0.81228 1.61156,-1.67972 l 1.55584,-1.55168 V 15.608465 4.2776446 h -3.3526299 -3.35263 l -1.53178,1.53457 c -0.84248,0.84401 -1.5855,1.56474 -1.65115,1.60161 -0.0985,0.0553 -0.2341,0.067 -0.77594,0.067 -0.73457,0 -0.85067,-0.0315 -0.9964,-0.27052 -0.0754,-0.12367 -0.0777,-0.22487 -0.0778,-3.46191 -1.6e-4,-3.67194003 -0.0139,-3.46891003 0.24795,-3.65570003 L 1.9834681,-5.4253333e-6 H 15.997108 30.010748 L 30.120638,0.08199457 c 0.0604,0.0451 0.1468,0.13149 0.19193,0.19193 0.0813,0.10895 0.082,0.13841 0.082,3.46643003 0,3.32802 -7e-4,3.35748 -0.082,3.46643 -0.0451,0.0604 -0.13145,0.14681 -0.19184,0.19193 -0.10343,0.0773 -0.172,0.0828 -1.18103,0.0949 -1.24379,0.0149 -1.44743,-0.0109 -1.61697,-0.20476 -0.0613,-0.0701 -0.5391,-0.77629 -1.06179,-1.56936 l -0.95035,-1.44193 h -3.31729 -3.31729 v 11.3503404 11.35034 l 1.58171,1.58199 1.58171,1.58199 0.0112,0.7261 c 0.0103,0.66637 0.005,0.73659 -0.0679,0.8535 -0.0435,0.0701 -0.13802,0.15959 -0.21012,0.19896 -0.12418,0.0678 -0.41492,0.0719 -5.52283,0.0784 -2.96546,0.004 -5.43806,-0.007 -5.49467,-0.0247 z"/>
    </svg>
);



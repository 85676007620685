/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Pocket_Source = {
    author?: string;
    publication_date?: string;
    source_id?: string;
    title?: string;
    type?: Pocket_Source.type;
    version?: string;
};

export namespace Pocket_Source {

    export enum type {
        KP = 'KP',
        ONLINE = 'Online',
        BOOK = 'Book',
        JOURNAL = 'Journal',
        OTHER = 'Other',
    }


}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GetTeamMember } from './GetTeamMember';

export type GetTeam = {
    business_unit?: GetTeam.business_unit;
    created_at?: string;
    created_by?: string;
    description?: string;
    is_active?: string;
    managed_by?: GetTeam.managed_by;
    members?: Array<GetTeamMember>;
    name?: string;
    owner_id?: string;
    parent_business_unit_id?: number;
    team_id?: number;
    updated_at?: string;
};

export namespace GetTeam {

    export enum business_unit {
        Y = 'Y',
        N = 'N',
    }

    export enum managed_by {
        OWNER = 'Owner',
        MEMBERS = 'Members',
    }


}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class StatsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieves Dashboard Stat
     * @param uploadStartDate Document Upload Start Date
     * @param uploadEndDate Document Upload End Date
     * @returns any Success
     * @throws ApiError
     */
    public dashboardStat(
uploadStartDate?: string,
uploadEndDate?: string,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stats',
            query: {
                'upload_start_date': uploadStartDate,
                'upload_end_date': uploadEndDate,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal system error`,
            },
        });
    }

}

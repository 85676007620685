/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Pocket_Resource } from './Pocket_Resource';
import type { SharingGroup } from './SharingGroup';

export type PostNPutPocketReport = {
    author_id?: string;
    citation?: PostNPutPocketReport.citation;
    endnotes?: string;
    footnotes?: string;
    group?: SharingGroup;
    html_text?: string;
    plain_text?: string;
    publication_date?: string;
    report_id?: string;
    resources?: Array<Pocket_Resource>;
    rte_text?: string;
    scope?: PostNPutPocketReport.scope;
    title: string;
};

export namespace PostNPutPocketReport {

    export enum citation {
        MLA = 'MLA',
        APA = 'APA',
        CHICAGO = 'Chicago',
        HARVARD = 'Harvard',
    }

    export enum scope {
        PRIVATE = 'Private',
        GROUP = 'Group',
    }


}

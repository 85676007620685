/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { LockManager } from './LockManager';
import type { Pocket_Resource } from './Pocket_Resource';
import type { SharingGroup } from './SharingGroup';

export type GetPocketReport = {
    /**
     * Current user's access role to the Pocket Report; O - Owner, G - Group shared, U - Unknown
     */
    access_role?: GetPocketReport.access_role;
    author_id?: string;
    citation?: GetPocketReport.citation;
    created_by?: string;
    created_on?: string;
    endnotes?: string;
    footnotes?: string;
    group?: SharingGroup;
    html_text?: string;
    lock_manager?: LockManager;
    plain_text?: string;
    publication_date?: string;
    report_id?: string;
    resources?: Array<Pocket_Resource>;
    rte_text?: string;
    scope?: GetPocketReport.scope;
    title?: string;
    updated_at?: string;
};

export namespace GetPocketReport {

    /**
     * Current user's access role to the Pocket Report; O - Owner, G - Group shared, U - Unknown
     */
    export enum access_role {
        O = 'O',
        G = 'G',
        U = 'U',
    }

    export enum citation {
        MLA = 'MLA',
        APA = 'APA',
        CHICAGO = 'Chicago',
        HARVARD = 'Harvard',
    }

    export enum scope {
        PRIVATE = 'Private',
        GROUP = 'Group',
    }


}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { basic_response } from '../models/basic_response';
import type { GetRoleRequest } from '../models/GetRoleRequest';
import type { PostRoleRequest } from '../models/PostRoleRequest';
import type { PutRoleRequest } from '../models/PutRoleRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UsersRolesRequestsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Creates a new User Requested Role
     * @param requestBody 
     * @returns basic_response Created
     * @throws ApiError
     */
    public create(
requestBody: PostRoleRequest,
): CancelablePromise<basic_response> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/roles/requests/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Internal system error`,
            },
        });
    }

    /**
     * Searches existing User Requested Roles
     * @param searchKeyword 
     * @returns GetRoleRequest Success
     * @throws ApiError
     */
    public search(
searchKeyword?: string,
): CancelablePromise<GetRoleRequest> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/roles/requests/search',
            query: {
                'search_keyword': searchKeyword,
            },
            errors: {
                400: `Bad Role Request`,
                500: `Internal system error`,
            },
        });
    }

    /**
     * Retrieves a User Requested Role
     * @param roleRequestId 
     * @returns GetRoleRequest Success
     * @throws ApiError
     */
    public get(
roleRequestId: number,
): CancelablePromise<GetRoleRequest> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/roles/requests/{role_request_id}',
            path: {
                'role_request_id': roleRequestId,
            },
            errors: {
                400: `Bad Request`,
                401: `Access token is missing or invalid`,
                404: `Role Request does not exists`,
                500: `Internal system error`,
            },
        });
    }

    /**
     * Captures Role Approver response to a requested role
     * @param roleRequestId 
     * @param requestBody 
     * @returns GetRoleRequest Success
     * @throws ApiError
     */
    public update(
roleRequestId: number,
requestBody: PutRoleRequest,
): CancelablePromise<GetRoleRequest> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/users/roles/requests/{role_request_id}',
            path: {
                'role_request_id': roleRequestId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Role Request does not exists`,
                500: `Internal system error`,
            },
        });
    }

}

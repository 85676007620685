/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PostTeamMember } from './PostTeamMember';

export type PostTeam = {
    business_unit: PostTeam.business_unit;
    description?: string;
    managed_by: PostTeam.managed_by;
    members?: Array<PostTeamMember>;
    name: string;
    owner_id: string;
    parent_business_unit_id?: number;
};

export namespace PostTeam {

    export enum business_unit {
        Y = 'Y',
        N = 'N',
    }

    export enum managed_by {
        OWNER = 'Owner',
        MEMBERS = 'Members',
    }


}

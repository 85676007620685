/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Upload Response Object
 */
export type document_upload_response = {
    detail?: string;
    status?: {
stage?: number;
status?: document_upload_response.status;
version?: number;
};
    title?: document_upload_response.title;
    type?: string;
};

export namespace document_upload_response {

    export enum status {
        CREATED = 'Created',
        READY = 'Ready',
        DELETED = 'Deleted',
    }

    export enum title {
        SUCCESS = 'success',
        FAILED = 'failed',
    }


}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Search = {
    author?: string;
    custom_shared_tag?: Array<string>;
    department?: Array<string>;
    exclusion?: string;
    kp_type?: Array<'Document' | 'Pocket' | 'Report'>;
    limit?: number;
    offset?: number;
    project?: string;
    publication_end_date?: string;
    publication_start_date?: string;
    purpose?: Array<string>;
    scope?: Search.scope;
    search_request: string;
    title?: string;
    upload_end_date?: string;
    upload_start_date?: string;
    user_id?: string;
};

export namespace Search {

    export enum scope {
        DRAFT = 'Draft',
        PRIVATE = 'Private',
        GROUP = 'Group',
        PUBLIC = 'Public',
    }


}

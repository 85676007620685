/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PutUser = {
    account_status?: PutUser.account_status;
    approved_at?: string;
    approved_by?: string;
    dept_id?: number;
    email_address?: string;
    first_name?: string;
    last_name?: string;
    middle_initial?: string;
    phone_number?: string;
    preferred_results_view?: PutUser.preferred_results_view;
    roles?: Array<number>;
};

export namespace PutUser {

    export enum account_status {
        ACTIVE = 'Active',
        INACTIVE = 'Inactive',
    }

    export enum preferred_results_view {
        LIST = 'List',
        VIEW = 'View',
        CARD = 'Card',
    }


}

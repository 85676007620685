/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PostUser = {
    dept_id: number;
    dod_id: string;
    email_address: string;
    first_name: string;
    last_name: string;
    middle_initial?: string;
    phone_number?: string;
    preferred_results_view?: PostUser.preferred_results_view;
    registration_reason: string;
    roles?: Array<number>;
};

export namespace PostUser {

    export enum preferred_results_view {
        LIST = 'List',
        VIEW = 'View',
        CARD = 'Card',
    }


}

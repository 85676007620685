/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { custom_personal_tag } from './custom_personal_tag';
import type { SharingGroup } from './SharingGroup';

export type GetDocument = {
    /**
     * Current user's access role to the KP; O - Owner, G - Group shared, P - Public, U - Unknown
     */
    access_role?: GetDocument.access_role;
    author?: string;
    created_at?: string;
    created_by?: string;
    custom_personal_tag?: Array<custom_personal_tag>;
    custom_shared_tag?: Array<string>;
    department?: number;
    document_id?: string;
    file_name?: string;
    file_page_count?: number;
    file_size?: string;
    file_type?: string;
    group?: SharingGroup;
    kp_type?: string;
    primary_sme_email?: string;
    primary_sme_name?: string;
    primary_sme_phone?: string;
    project?: string;
    publication_date?: string;
    purpose?: string;
    scope?: GetDocument.scope;
    secondary_sme_email?: string;
    secondary_sme_name?: string;
    secondary_sme_phone?: string;
    status?: {
stage?: number;
status?: GetDocument.status;
version?: number;
};
    title?: string;
    updated_at?: string;
};

export namespace GetDocument {

    /**
     * Current user's access role to the KP; O - Owner, G - Group shared, P - Public, U - Unknown
     */
    export enum access_role {
        O = 'O',
        G = 'G',
        P = 'P',
        U = 'U',
    }

    export enum scope {
        DRAFT = 'Draft',
        PRIVATE = 'Private',
        GROUP = 'Group',
        PUBLIC = 'Public',
    }

    export enum status {
        CREATED = 'Created',
        READY = 'Ready',
        DELETED = 'Deleted',
    }


}

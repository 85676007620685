/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { login } from '../models/login';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthorizeService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Login to access secure APIs
     * @param requestBody 
     * @returns any Successfully logged in
     * @throws ApiError
     */
    public login(
requestBody: login,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Internal system error`,
            },
        });
    }

}

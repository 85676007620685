/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { basic_response } from '../models/basic_response';
import type { GetRole } from '../models/GetRole';
import type { PostNPutRole } from '../models/PostNPutRole';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UsersRolesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Creates a new Role
     * @param requestBody 
     * @returns basic_response Created
     * @throws ApiError
     */
    public create(
requestBody: PostNPutRole,
): CancelablePromise<basic_response> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/roles',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                409: `User Role already exists`,
                500: `Internal system error`,
            },
        });
    }

    /**
     * Searches existing Roles
     * @param searchKeyword 
     * @returns GetRole Success
     * @throws ApiError
     */
    public search(
searchKeyword?: string,
): CancelablePromise<GetRole> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/roles/search',
            query: {
                'search_keyword': searchKeyword,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal system error`,
            },
        });
    }

    /**
     * Deletes a Role
     * @param roleId 
     * @returns basic_response Success
     * @throws ApiError
     */
    public delete(
roleId: number,
): CancelablePromise<basic_response> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/roles/{role_id}',
            path: {
                'role_id': roleId,
            },
            errors: {
                400: `Bad Request`,
                403: `Role is in use.`,
                404: `Role does not exists`,
                500: `Internal system error`,
            },
        });
    }

    /**
     * Retrieves a Role
     * @param roleId 
     * @returns GetRole Success
     * @throws ApiError
     */
    public get(
roleId: number,
): CancelablePromise<GetRole> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/roles/{role_id}',
            path: {
                'role_id': roleId,
            },
            errors: {
                400: `Bad Request`,
                401: `Access token is missing or invalid`,
                404: `Role does not exists`,
                500: `Internal system error`,
            },
        });
    }

    /**
     * Updates Role name and description
     * @param roleId 
     * @param requestBody 
     * @returns GetRole Success
     * @throws ApiError
     */
    public update(
roleId: number,
requestBody: PostNPutRole,
): CancelablePromise<GetRole> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/users/roles/{role_id}',
            path: {
                'role_id': roleId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Role does not exists`,
                500: `Internal system error`,
            },
        });
    }

}
